import React, { FC } from 'react'
import cookie from 'js-cookie'
import {
  CookieWrapper,
  FormAction,
} from 'components/block/cookieNotification/CookieAccept.styled'
import { Cookies } from 'constants/cookie'
import { FormattedMessage } from 'react-intl'
import { Link, useLocation } from 'react-router-dom'
import { NoticePaths } from 'components/page/notice/components.notice.paths'
import { Paths } from 'components/components.paths'
import { PrimaryClickButton } from 'components/presentational/announce/buttons/PrimaryButton'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { updateCookiesAction } from 'actions/system/setBootstrapDataAction'
import { useDispatch } from 'react-redux'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

const mergedPaths: string[] = [
  Paths.policy,
  Paths.agreement,
  Paths.policyPlain,
  Paths.agreementPlain,
  Paths.paymentAnswerSubscribe,
  Paths.externalPayment,
  Paths.livenessRedirect,
  NoticePaths.noAdultForbidden,
  NoticePaths.confirmAges,
  NoticePaths.captcha,
]

const CookieNotification: FC = () => {
  const { language } = useShallowEqualSelector(({ system: { language } }) => ({
    language,
  }))

  const locale = language && language.code ? language.code : ''
  const dispatch = useDispatch()
  const location = useLocation()

  const notShowCookiesPaths = mergedPaths.map((path) =>
    mergeAllUrls(locale, path)
  )

  const handleClick = (value: boolean) => {
    cookie.set(Cookies.privatePolicyFooter, JSON.stringify(value), {
      expires: 14,
    })

    dispatch(updateCookiesAction(Cookies.privatePolicyFooter, value))
  }

  if (notShowCookiesPaths.includes(location.pathname)) {
    return null
  }

  return (
    <CookieWrapper data-name="cookie-block-use">
      <div>
        <FormattedMessage
          id="cookie.notification"
          defaultMessage="Мы используем файлы cookie. Продолжая пользоваться сайтом, вы даете согласие на их использование в соответствии с {linkPolicy} и принимаете {linkAgreement}."
          values={{
            linkPolicy: (
              <Link to={mergeAllUrls(locale, Paths.policy)}>
                <FormattedMessage
                  id="cookie.notification.link.policy"
                  defaultMessage="политикой конфиденциальности."
                />
              </Link>
            ),
            linkAgreement: (
              <Link to={mergeAllUrls(locale, Paths.agreement)}>
                <FormattedMessage
                  id="link.agreement"
                  defaultMessage="соглашение"
                />
              </Link>
            ),
          }}
        />
      </div>
      <FormAction>
        <PrimaryClickButton
          onClick={() => handleClick(true)}
          data-name="accept-adult-action"
        >
          <FormattedMessage id="label.button.good" defaultMessage="OK" />
        </PrimaryClickButton>
      </FormAction>
    </CookieWrapper>
  )
}

export default CookieNotification
